<template>
  <div>
    <v-system-bar>Dispensationen (Kalender)</v-system-bar>
    <v-calendar
      ref="calendar"
      type="month"
      locale="de-CH"
      :weekdays="[1, 2, 3, 4, 5, 6, 0]"
      :events="events"
      :value="dateSpan.startDate"
      @click:event="
        (item) =>
          item.event.id
            ? $router.push({
                name: 'DispensationEdit',
                params: {
                  id: item.event.id,
                },
              })
            : ''
      "
    >
    </v-calendar>
  </div>
</template>

<script>
import { personName } from 'common/utils/people.js';
export default {
  name: 'CalendarComponent',
  props: ['items', 'focus', 'dateSpan'],
  data() {
    return { holidays: [] };
  },
  computed: {
    events() {
      return [
        ...this.items.map((el) => ({
          id: el.id,
          name: personName(el.student),
          start: el.startDate,
          end: el.endDate,
          color: el.physicalEducation ? 'info' : 'primary',
          timed: false,
        })),
        ...this.holidays,
      ];
    },
  },
  watch: {
    dateSpan() {
      this.fetchHolidays();
    },
  },
  methods: {
    async fetchHolidays() {
      const data = await this.apiList({
        resource: 'common/holiday',
        query: `startDate=${this.dateSpan.startDate}&endDate=${this.dateSpan.endDate}`,
      });
      this.holidays = data.map((el) => ({
        name: el.name,
        start: el.startDate,
        end: el.endDate,
        color: 'warning',
        timed: false,
      }));
    },
  },
};
</script>
