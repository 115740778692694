var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-system-bar',[_vm._v("Dispensationen (Kalender)")]),_c('v-calendar',{ref:"calendar",attrs:{"type":"month","locale":"de-CH","weekdays":[1, 2, 3, 4, 5, 6, 0],"events":_vm.events,"value":_vm.dateSpan.startDate},on:{"click:event":(item) =>
        item.event.id
          ? _vm.$router.push({
              name: 'DispensationEdit',
              params: {
                id: item.event.id,
              },
            })
          : ''}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }