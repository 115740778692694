<template>
  <Page color="info" title="Dispensationen">
    <template slot="help">
      <Markdown :value="helpText" />
    </template>
    <template slot="extension">
      <v-tabs
        background-color="info darken-1"
        slider-color="white"
        :align-with-title="!$vuetify.breakpoint.xs"
      >
        <v-tab
          :to="{
            name: 'DispensationsView',
            params: { view: 'list' },
          }"
          >Liste</v-tab
        >
        <v-tab
          :to="{
            name: 'DispensationsView',
            params: { view: 'calendar' },
          }"
          >Kalender</v-tab
        >
      </v-tabs>
    </template>
    <v-btn
      color="success"
      fab
      fixed
      bottom
      right
      :to="{ name: 'DispensationAdd' }"
      ><v-icon>mdi-plus</v-icon></v-btn
    >

    <v-container class="mt-n4">
      <v-row class="align-center justify-center">
        <SchoolClassPicker outlined text v-model="schoolClass" />
        <v-spacer></v-spacer>
        <TermPicker
          v-if="view == 'list'"
          outlined
          text
          v-model="term"
          :range="[-3, 1]"
        />
        <span v-if="view == 'calendar'">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                @click="prevMonth()"
                v-if="!$vuetify.breakpoint.mobile"
              >
                <v-icon>mdi-chevron-double-left</v-icon>
              </v-btn>
            </template>
            <span>früher</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                @click="nextMonth()"
                v-if="!$vuetify.breakpoint.mobile"
              >
                <v-icon>mdi-chevron-double-right</v-icon>
              </v-btn>
            </template>
            <span>später</span>
          </v-tooltip>

          <v-menu
            v-model="dateMenu"
            offset-y
            :nudge-left="40"
            :close-on-content-click="false"
            transition="scale-transition"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn outlined text v-bind="attrs" v-on="on">{{ month }} </v-btn>
            </template>
            <v-date-picker
              color="primary"
              v-model="focus"
              type="month"
              locale="de-CH"
              first-day-of-week="1"
              show-week
              :locale-first-day-of-year="4"
              @input="dateMenu = false"
            >
            </v-date-picker>
          </v-menu>
        </span>
        <v-spacer />
        <v-switch
          color="info"
          v-model="physicalEducationFilter"
          inset
          prepend-icon="mdi-run"
        />
        <BtnSpacer />
        <PersonInput
          v-if="schoolClass"
          label="Schüler:in"
          dense
          hide-details=""
          v-model="studentFilter"
          :group="schoolClass.code"
          clearable
        />
        <BtnSpacer />
        <v-text-field
          v-if="!$vuetify.breakpoint.mobile"
          type="search"
          dense
          hide-details=""
          v-model="search"
          label="Grund"
          append-icon="mdi-magnify"
          clearable
        />
      </v-row>
    </v-container>
    <v-card :loading="loading">
      <DispensationsTable
        @selectStudent="selectStudent"
        v-if="view == 'list'"
        :items="itemsFiltered"
      />
      <DispensationsCalendar
        @selectStudent="selectStudent"
        v-touch="{
          left: () => nextMonth(),
          right: () => prevMonth(),
        }"
        v-if="view == 'calendar'"
        :items="itemsFiltered"
        :focus="focus"
        :dateSpan="dateSpan"
      />
    </v-card>
    <router-view :term="term" :schoolClass="schoolClass" @update="fetchData" />
  </Page>
</template>

<script>
import DispensationsCalendar from "./Components/DispensationsCalendar.vue";
import DispensationsTable from "./Components/DispensationsTable.vue";
import PersonInput from "common/components/PersonInput.vue";
import SchoolClassPicker from "@/components/SchoolClassPicker.vue";
import TermPicker from "common/components/TermPicker.vue";

import {
  addDays,
  findEndOfMonth,
  formatMonth,
  today,
} from "common/utils/date.js";

const helpText = `
Hier können Klassenlehrpersonen Dispensationen erfassen, bearbeiten und löschen:

- Wähle die entsprechende Klasse aus (falls mehr als eine Klasse zur Verfügung stehen)
- Verwende die Filter- und Suchfunktion um nach Peronen zu filtern resp. nach Grund zu suchen.
- Passe Dispensationen mit einem Klick an
- Erstelle mit dem +-Button (unten rechts) neue Dispensationen.
`;

export default {
  name: "Dispensations",
  components: {
    DispensationsCalendar,
    DispensationsTable,
    PersonInput,
    SchoolClassPicker,
    TermPicker,
  },
  props: ["view"],
  data() {
    return {
      helpText,
      focus: "",
      dateMenu: false,
      loading: false,
      items: [],
      month: "",
      physicalEducationFilter: true,
      search: "",
      schoolClass: null,
      studentFilter: null,
      term: {},
      dateSpan: {
        startDate: null,
        endData: null,
      },
      fab: false,
    };
  },
  computed: {
    itemsFiltered() {
      return this.items.filter(
        (el) =>
          (this.physicalEducationFilter || !el.physicalEducation) &&
          (!this.studentFilter || this.studentFilter.id == el.student.id) &&
          (!this.search ||
            (el.description &&
              el.description.toLowerCase().includes(this.search.toLowerCase())))
      );
    },
  },
  watch: {
    dateSpan(oldVal, newVal) {
      if (
        oldVal.startDate != newVal.startDate &&
        oldVal.endDate != newVal.endDate
      ) {
        this.fetchData();
      }
    },
    focus() {
      this.month = formatMonth(this.focus);
      if (this.view == "calendar") {
        // console.log(this.focus);
        this.dateSpan = {
          startDate: this.focus + "-01",
          endDate: findEndOfMonth(this.focus + "-01"),
        };
        // console.log(this.dateSpan);
      }
    },
    schoolClass() {
      this.fetchData();
    },
    term() {
      if (this.view == "list") {
        this.dateSpan = {
          startDate: this.term.startDate,
          endDate: this.term.endDate,
        };
      }
    },
    view() {
      if (this.view == "list") {
        if (this.term) {
          this.dateSpan = {
            startDate: this.term.startDate,
            endDate: this.term.endDate,
          };
        }
      } else if (this.view == "calendar") {
        this.dateSpan = {
          startDate: this.focus + "-01",
          endDate: findEndOfMonth(this.focus + "-01"),
        };
      }
    },
  },
  methods: {
    async fetchData() {
      if (
        !this.schoolClass ||
        !this.dateSpan.startDate ||
        !this.dateSpan.endDate
      ) {
        return;
      }
      this.loading = true;
      this.items = await this.apiList({
        resource: "absence/dispensation",
        query: `schoolClass=${this.schoolClass.id}&startDate=${this.dateSpan.startDate}&endDate=${this.dateSpan.endDate}`,
      });
      this.loading = false;
    },
    nextMonth() {
      const date = addDays(this.focus + "-01", 32);
      const index = date.lastIndexOf("-");
      this.focus = date.substring(0, index);
    },
    prevMonth() {
      const date = addDays(this.focus + "-01", -10);
      const index = date.lastIndexOf("-");
      this.focus = date.substring(0, index);
    },
    selectStudent(id) {
      this.studentFilter = this.items.find((el) => (el.id = id));
    },
  },
  created() {
    const date = today();
    const index = date.lastIndexOf("-");
    this.focus = date.substring(0, index);
  },
};
</script>
